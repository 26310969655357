{
  "name": "superProductivity",
  "version": "10.0.1",
  "description": "Experience the best ToDo app for digital professionals and get more done! Super Productivity comes with integrated time-boxing and time tracking capabilities and you can load your task from your calendars and from Jira, Gitlab, GitHub, Open Project and others all into a single ToDo list.",
  "main": "./electron/main.js",
  "author": "Johannes Millan <contact@super-productivity.com> (http://super-productivity.com)",
  "license": "MIT",
  "homepage": "http://super-productivity.com",
  "repository": {
    "type": "git",
    "url": "git://github.com/johannesjo/super-productivity.git"
  },
  "browserslist": {
    "development": [
      "defaults and fully supports es6-module"
    ],
    "production": [
      "defaults and fully supports es6-module"
    ],
    "modern": [
      "defaults and fully supports es6-module"
    ]
  },
  "keywords": [
    "ToDo",
    "Task Management",
    "Jira",
    "GitHub",
    "Time Tracking"
  ],
  "husky": {
    "hooks": {
      "pre-commit": "pretty-quick --staged && npm run lint",
      "pre-push": "npm run lint && npm run test"
    }
  },
  "scripts": {
    "ng": "ng",
    "start": "npm run electron:build && cross-env NODE_ENV=DEV electron .",
    "startFrontend": "ng serve",
    "serveProd": "ng serve --configuration production",
    "removeWOFF1": "node ./tools/remove-woff.js",
    "buildFrontend:prod:es6": "npm run prebuild && cross-env BROWSERSLIST_ENV='modern' node --max_old_space_size=4096 ./node_modules/@angular/cli/bin/ng build --configuration production && npm run removeWOFF1",
    "buildFrontend:prodWeb": "npm run prebuild && node --max_old_space_size=4096 ./node_modules/@angular/cli/bin/ng build  --configuration productionWeb",
    "buildFrontend:stage:es6": "npm run prebuild && cross-env BROWSERSLIST_ENV='modern' ng build --configuration stage && npm run removeWOFF1",
    "buildFrontend:stageWeb": "npm run prebuild && ng build --configuration stageWeb",
    "buildFrontend:prod:watch": "npm run prebuild && ng build --configuration production --watch",
    "buildAllElectron:prod": "npm run preCheck && npm run buildFrontend:prod:es6 && npm run electron:build",
    "buildAllElectron:stage": "npm run preCheck && npm run buildFrontend:stage:es6 && npm run electron:build",
    "buildAllElectron:noTests:prod": "npm run lint && npm run buildFrontend:prod:es6 && npm run electron:build",
    "build": "npm run buildAllElectron:noTests:prod",
    "test": "cross-env TZ='Europe/Berlin' ng test --watch=false",
    "test:watch": "cross-env TZ='Europe/Berlin' ng test --browsers ChromeHeadless",
    "lint": "ng lint",
    "prebuild": "node ./tools/git-version.js",
    "preCheck": "npm run lint && npm run test & npm run int:test && npm run e2e",
    "e2e": "cross-env TZ='' DETECT_CHROMEDRIVER_VERSION=true SKIP_POST_INSTALL=true npm i -D chromedriver --legacy-peer-deps && tsc --project e2e/tsconfig.e2e.json && start-server-and-test 'ng serve --no-live-reload' 4200 'nightwatch -c ./e2e/nightwatch.conf.js --suiteRetries 1 --retries 0'",
    "e2e:tag": "killall chromedriver; rm -R ./out-tsc; tsc --project e2e/tsconfig.e2e.json && nightwatch -c ./e2e/nightwatch.conf.js --suiteRetries 0 --retries 0 --tag ",
    "electron": "NODE_ENV=PROD electron .",
    "electron:build": "tsc -p electron/tsconfig.electron.json",
    "electron:watch": "tsc -p electron/tsconfig.electron.json --watch",
    "electronBuilderOnly": "electron-builder",
    "empty": "echo 'EMPTY YEAH'",
    "pack": "electron-builder --dir",
    "localInstall": "sudo echo 'Starting local install' && rm -Rf ./dist/ && rm -Rf ./app-builds/ && npm run buildAllElectron:stage && electron-builder --linux deb && sudo dpkg -i app-builds/superProductivity*.deb",
    "localInstall:prod": "sudo echo 'Starting local install PROD' && rm -Rf ./dist/ && rm -Rf ./app-builds/ && npm run buildAllElectron:prod && electron-builder --linux deb && sudo dpkg -i app-builds/superProductivity*.deb",
    "localInstall:quick": "sudo echo 'Starting local install QUICK' && rm -Rf ./dist/ && rm -Rf ./app-builds/ && npm run buildFrontend:stage:es6 && npm run electron:build && electron-builder --linux deb && sudo dpkg -i app-builds/superProductivity*.deb",
    "localInstall:test:snap": "sudo echo 'Starting local install SNAP QUICK' && rm -Rf ./dist/ && rm -Rf ./app-builds/ && npm run buildFrontend:stage:es6 && npm run electron:build && electron-builder --linux snap && sudo snap install --dangerous app-builds/superProductivity*.snap",
    "localInstall:mac": "sudo echo 'Starting local install MAC. Don`t forget APPLEID & APPLEIDPASS !!' && npm run buildAllElectron:noTests:prod && sudo echo '' && electron-builder && sudo cp -rf app-builds/mac/superProductivity.app/ /Applications/superProductivity.app",
    "dist": "npm run buildAllElectron:prod && electron-builder",
    "dist:only": "electron-builder",
    "dist:linuxAndWin": "npm run buildAllElectron:prod && electron-builder --linux --win",
    "dist:win": "npm run buildAllElectron:noTests:prod && electron-builder --win",
    "dist:win:only": "electron-builder --win",
    "dist:win:appx": "npm run buildAllElectron:prod && electron-builder --win --config=build/electron-builder.appx.yaml",
    "dist:win:store": "git pull && npm run && copy electron-builder.win-store.yaml electron-builder.yaml && npm run dist:win && git checkout electron-builder.yaml || git checkout electron-builder.yaml",
    "dist:mac:dl": "cp tools/mac-profiles/dl.provisionprofile embedded.provisionprofile && electron-builder --mac",
    "dist:mac:mas": "cp tools/mac-profiles/mas.provisionprofile embedded.provisionprofile; electron-builder --mac mas --config=build/electron-builder.mas.yaml",
    "dist:mac:mas:buildOnly": "electron-builder --mac mas --config=build/electron-builder.mas.yaml",
    "dist:mac:mas:dev": "cp tools/mac-profiles/mas-dev.provisionprofile embedded.provisionprofile; electron-builder --mac mas-dev --config=build/electron-builder.mas-dev.yaml",
    "dist:mac:mas:x64": "cp tools/mac-profiles/mas.provisionprofile embedded.provisionprofile; electron-builder --mac mas --config=build/electron-builder.mas.yaml --x64",
    "release": "npm run release.changelog && npm run dist",
    "release.changelog": "conventional-changelog -i CHANGELOG.md -s -p angular",
    "version": "npm run prebuild && npm run release.changelog && git add -A",
    "buildSchema": "cd tools/schematics && npm run build && cd ../../ && npm i file:./tools/schematics --legacy-peer-deps",
    "int": "node ./tools/extract-i18n-single.js",
    "int:watch": "node ./tools/extract-i18n-watch.js",
    "int:find": "ngx-translate-extract --input ./src --output ./src/assets/i18n/*.json  --sort --format namespaced-json --marker _",
    "int:clean": "ngx-translate-extract --input ./src --output ./src/assets/i18n/*.json --clean --sort --format namespaced-json --marker _",
    "int:test": "node ./tools/test-lng-files.js"
  },
  "publish": [
    {
      "provider": "github",
      "repo": "super-productivity",
      "owner": "johannesjo"
    }
  ],
  "dependencies": {
    "electron-dl": "^3.5.2",
    "electron-localshortcut": "^3.2.1",
    "electron-log": "^5.1.2",
    "electron-window-state": "^5.0.3",
    "fs-extra": "^11.1.1",
    "node-fetch": "^2.7.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.1.4",
    "@angular-eslint/builder": "^18.0.1",
    "@angular-eslint/eslint-plugin": "^18.0.1",
    "@angular-eslint/eslint-plugin-template": "^18.0.1",
    "@angular-eslint/schematics": "^18.0.1",
    "@angular-eslint/template-parser": "^18.0.1",
    "@angular/animations": "^18.1.4",
    "@angular/cdk": "^18.1.4",
    "@angular/cli": "^18.1.4",
    "@angular/common": "^18.1.4",
    "@angular/compiler": "^18.1.4",
    "@angular/compiler-cli": "^18.1.4",
    "@angular/core": "^18.1.4",
    "@angular/forms": "^18.1.4",
    "@angular/language-service": "^18.1.4",
    "@angular/material": "^18.1.4",
    "@angular/material-moment-adapter": "^18.1.4",
    "@angular/platform-browser": "^18.1.4",
    "@angular/platform-browser-dynamic": "^18.1.4",
    "@angular/platform-server": "^18.1.4",
    "@angular/router": "^18.1.4",
    "@angular/service-worker": "^18.1.4",
    "@electron/notarize": "^2.3.2",
    "@fontsource/roboto": "^4.5.8",
    "@nextcloud/cdav-library": "^1.1.0",
    "@ngrx/data": "^18.0.2",
    "@ngrx/effects": "^18.0.2",
    "@ngrx/entity": "^18.0.2",
    "@ngrx/schematics": "^18.0.2",
    "@ngrx/store": "18.0.2",
    "@ngrx/store-devtools": "^18.0.2",
    "@ngx-formly/core": "6.3.6",
    "@ngx-formly/material": "6.3.6",
    "@ngx-translate/core": "^14.0.0",
    "@ngx-translate/http-loader": "^7.0.0",
    "@schematics/angular": "^18.0.4",
    "@types/electron-localshortcut": "^3.1.3",
    "@types/file-saver": "^2.0.5",
    "@types/hammerjs": "^2.0.45",
    "@types/jasmine": "^3.10.2",
    "@types/jasminewd2": "~2.0.13",
    "@types/moment-duration-format": "^2.2.6",
    "@types/node": "20.12.4",
    "@types/node-fetch": "^2.6.6",
    "@types/object-path": "^0.11.4",
    "@typescript-eslint/eslint-plugin": "7.13.1",
    "@typescript-eslint/parser": "7.13.1",
    "angular-material-css-vars": "^7.0.0",
    "angular-mentions": "^1.5.0",
    "axios": "^1.6.0",
    "chai": "^5.1.1",
    "chart.js": "^4.4.3",
    "chromedriver": "^127.0.3",
    "chrono-node": "^2.7.6",
    "clipboard": "^2.0.8",
    "conventional-changelog-cli": "^4.1.0",
    "cross-env": "^7.0.3",
    "detect-it": "^4.0.1",
    "dragula": "^3.7.3",
    "electron": "^31.3.1",
    "electron-builder": "^24.13.3",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-import": "^2.29.1",
    "eslint-plugin-jsdoc": "48.2.12",
    "eslint-plugin-prefer-arrow": "1.2.3",
    "eslint-plugin-prettier": "^5.1.3",
    "file-saver": "^2.0.2",
    "glob": "^9.3.5",
    "hammerjs": "^2.0.8",
    "helpful-decorators": "^2.1.0",
    "husky": "^4.2.5",
    "ical.js": "^2.0.1",
    "idb": "^8.0.0",
    "jasmine-core": "^4.0.0",
    "jasmine-marbles": "^0.8.4",
    "jasmine-spec-reporter": "~7.0.0",
    "jira2md": "git+https://github.com/johannesjo/J2M.git",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.1.1",
    "karma-cli": "^2.0.0",
    "karma-coverage-istanbul-reporter": "~3.0.2",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^1.6.0",
    "lz-string": "^1.5.0",
    "marked": "^12.0.2",
    "moment": "2.30.1",
    "moment-duration-format": "^2.2.2",
    "nanoid": "^4.0.0",
    "new-github-issue-url": "^0.2.1",
    "ng2-charts": "^6.0.0",
    "ng2-dragula": "^5.1.0",
    "ngx-markdown": "^18.0.0",
    "nightwatch": "^3.6.3",
    "object-path": "^0.11.8",
    "p-throttle": "^3.1.0",
    "prettier": "^3.3.2",
    "pretty-quick": "^4.0.0",
    "query-string": "^7.1.1",
    "rxjs": "^6.6.7",
    "shepherd.js": "^11.2.0",
    "stacktrace-js": "^2.0.1",
    "start-server-and-test": "^1.14.0",
    "ts-node": "~10.9.1",
    "tslib": "^2.4.1",
    "typescript": "~5.4.5",
    "utils-decorators": "^2.0.8",
    "webdav": "~4.11.3",
    "zone.js": "~0.14.7"
  },
  "resolutions": {
    "sass": "1.32.6"
  },
  "overrides": {
    "ng2-dragula": {
      "@angular/common": "$@angular/common",
      "@angular/core": "$@angular/core",
      "@angular/animations": "$@angular/animations"
    },
    "angular-mentions": {
      "@angular/common": "$@angular/common"
    }
  }
}
